<template>
  <a-form-model v-if="showFrom" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="生成方式">
      <a-radio-group v-model="model" @change="modelOnChange">
        <a-radio :value="1"> 随机（随机3，1，2）</a-radio>
        <a-radio :value="2"> 正序（正序1，2，3）</a-radio>
        <a-radio :value="3"> 倒序（倒序3，2，1）</a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="地区选择">
      <a-select
        mode="multiple"
        style="width: 100%"
        placeholder="Please select"
        @change="areaHandleChange"
      >
        <a-select-option v-for="i in Object.keys(area)" :key="i">
          {{ area[i] }}-{{ i }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="号码前方是否加0">
      <a-switch @change="addZeroOnChange" />
    </a-form-model-item>

    <a-form-model-item label="运营商选择">
      <a-select
        mode="multiple"
        style="width: 100%"
        placeholder="Please select"
        @change="operatorHandleChange"
      >
        <a-select-option v-for="i in Object.keys(operator)" :key="i">
          {{ i }}-{{ operator[i] }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="导出方式">
      <a-radio-group
        v-model="export_model"
        name="radioGroup"
        :default-value="1"
      >
        <a-radio :value="1"> 文本 </a-radio>
        <a-radio :value="2"> txt </a-radio>
        <a-radio :value="3"> csv </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="生成数量">
      <a-input v-model="export_count" type="Number" min="1" />
    </a-form-model-item>
    <a-button
      class="submit"
      :loading="loading"
      @click="onSubmit"
      type="primary"
      block
    >
      生成
    </a-button>
  </a-form-model>
  <textarea v-else v-html="textList" cols="300" rows="100"></textarea>
</template>
<script>
import XLSX from "xlsx";
export default {
  name: "App",
  data() {
    return {
      showFrom: true,
      model: 1,
      area: {
        296: "安江",
        254: "巴地-头顿",
        204: "北江",
        209: "北件省",
        291: "薄寮",
        222: "北宁省",
        275: "槟知",
        256: "平定省",
        274: "平阳省",
        271: "平福",
        252: "平顺省",
        290: "金瓯",
        292: "芹苴",
        206: "高平省",
        236: "岘港",
        262: "多乐",
        261: "同奈",
        277: "同塔",
        269: "嘉莱",
        219: "河江",
        226: "河南",
        34: "河西",
        239: "河静",
        220: "海阳",
        225: "海防",
        24: "河内",
        28: "胡志明市",
        218: "和平",
        221: "兴安",
        258: "庆和",
        297: "坚江",
        260: "昆嵩",
        214: "老街",
        213: "莱州",
        205: "谅山",
        272: "隆安",
        228: "南定",
        238: "义安",
        259: "宁顺",
        229: "宁平",
        210: "富寿",
        257: "富安",
        232: "广平",
        235: "广南",
        203: "广宁",
        255: "广义",
        233: "广治",
        299: "朔庄",
        212: "山罗",
        227: "太平",
        208: "泰原",
        237: "清化",
        234: "承天-顺化",
        263: "林同省",
        294: "茶荣",
        276: "西宁",
        273: "前江",
        207: "宣光",
        270: "永隆",
        211: "永福",
        216: "安沛",
      },
      operator: {
        90: "MobiFone",
        91: "Vinaphone",
        92: "Vietnamobile (previously known as HT Mobile)",
        93: "MobiFone",
        94: "Vinaphone",
        95: "S-Fone",
        96: "EVN Telecom[1]",
        97: "Viettel Mobile",
        98: "Viettel Mobile",
        996: "GTel (traded as Beeline)",
        99: "Indochina Telecom (planned)",
        70: "MobiFone",
        79: "MobiFone",
        77: "MobiFone",
        83: "Vinaphone",
        84: "Vinaphone",
        85: "Vinaphone",
        76: "MobiFone",
        81: "Vinaphone",
        78: "MobiFone",
        82: "Vinaphone",
        33: "Viettel Mobile",
        34: "Viettel Mobile",
        35: "Viettel Mobile",
        36: "Viettel Mobile",
        37: "Viettel Mobile",
        38: "Viettel Mobile",
        39: "Viettel Mobile",
        56: "Vietnamobile",
        58: "Vietnamobile (previously known as HT Mobile)",
        59: "GTel (traded as Beeline)",
      },
      addZero: false,
      area_value: 0,
      operator_value: 0,
      export_model: 1,
      export_count: 1,
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      loading: false,
      textList: "",
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      try {
        let prefix = [],
          data = [],
          max = "999999999";
        if (!this.area_value) {
          this.$notification.error({
            description: "至少选择一个地区！",
          });
          this.loading = false;
          return false;
        }
        if (!this.operator_value) {
          this.$notification.error({
            description: "至少选择一个运营商",
          });
          this.loading = false;
          return false;
        }
        for (let ope of this.operator_value) {
          for (let are of this.area_value) {
            prefix.push([ope, are].join(""));
          }
        }
        switch (this.model) {
          case 1:
            for (let i = 1; i <= this.export_count; i++) {
              let p = prefix[Math.floor(Math.random() * prefix.length)];
              let m = 9 - String(p).length;
              let l = Math.floor(
                Math.random() * Number(max.substring(0, m)) + 1
              );
              let d = [p, ("0000000000000000" + l).substr(-m)].join("");
              if (this.addZero) {
                d = ["0", d].join("");
              }
              data.push(d);
            }
            break;
          case 2: {
            prefix = prefix.sort((a, b) => a > b);
            let i = 1,
              pi = 0,
              count = 0;
            while (count < this.export_count && prefix[pi]) {
              count++;
              let p = prefix[pi];
              let s = [p, ("00000000000" + i).substr(-(9 - p.length))].join("");
              if (this.addZero) {
                s = ["0", s].join("");
              }
              data.push(s);
              let m = (String(p) + "99999999999999").substr(0, 9);
              if (Number(m) == Number(s)) {
                i = 1;
                pi++;
              } else {
                i++;
              }
            }
            break;
          }
          case 3: {
            prefix = prefix.sort((a, b) => a < b);
            let i = 5,
              pi = 0,
              count = this.export_count;
            while (count > 0 && prefix[pi]) {
              count--;
              let p = prefix[pi];
              let s = [p, ("00000000000000" + i).substr(-(9 - p.length))].join(
                ""
              );
              if (this.addZero) {
                s = ["0", s].join("");
              }
              data.push(s);
              let m = (String(p) + "00000000000").substr(0, 9);
              if (Number(m) + 1 == Number(s)) {
                i = 9999999;
                pi++;
              } else {
                i--;
              }
            }
            break;
          }
          default:
            this.$notification.error({
              description: "生成方式错误！",
            });
        }
        switch (this.export_model) {
          case 1:
            this.textList = data.join("&#10;");
            this.showFrom = false;
            break;
          case 2: {
            let urlObject = window.URL || window.webkitURL || window;
            let export_blob = new Blob([data.join("\n")]);
            let save_link = document.createElementNS(
              "http://www.w3.org/1999/xhtml",
              "a"
            );
            save_link.href = urlObject.createObjectURL(export_blob);
            save_link.download = new Date().toLocaleString() + ".txt";
            save_link.click();
            break;
          }
          case 3: {
            let worksheet = XLSX.utils.aoa_to_sheet(
              data.map((item) => {
                return [item];
              })
            );
            let new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "page1");
            XLSX.writeFile(
              new_workbook,
              new Date().toLocaleDateString() + ".xlsx"
            );
            break;
          }
          default:
            this.$notification.error({
              description: "导出方式错误",
            });
        }
      } catch (error) {
        this.$notification.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    addZeroOnChange(checked) {
      this.addZero = checked;
    },
    modelOnChange(e) {
      this.model = e.target.value;
    },
    areaHandleChange(value) {
      this.area_value = value;
    },
    operatorHandleChange(value) {
      this.operator_value = value;
    },
  },
};
</script>

<style>
html,
body,
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0 auto;
}

.submit {
  max-width: 270px;
}
</style>
